















import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'
export default mixins(Section).extend({
  name: 'Text12'
})
